import React from 'react'
import serviceone from '../../../static/images/serviceone.png'
import productfour from '../../../static/images/product3.png'

export default function sectionone() {
  return (
    <div>
        <section class=" px-12 lg:py-4 lg:px-20 bg-white overflow-hidden" style={{fontFamily:'poppins'}}>
  <div class="container px-4 mx-auto">
    <div class="flex flex-wrap xl:items-center -m-8">
      <div class="w-full md:w-1/2 p-8">
        <img class="pt-12 lg:pt-4 lg:mx-auto transform hover:translate-y-3 transition ease-in-out lg:w-full lg:h-full duration-1000" src={productfour} alt=""/>
      </div>
      <div class="w-full md:w-1/2 p-8">
        <div class="md:max-w-xl">
          <p class="mb-2 text-sm text-indigo-900 font-semibold uppercase tracking-px">&#x1F44B; Our Product</p>
          <h2 class=" mb-4 lg:mb-4 text-2xl md:text-4xl font-bold text-indigo-800 font-heading tracking-px-n leading-tight">UnLimit</h2>
          <p class="max-w-xl text-gray-500 leading-loose">Solution for Employee benefits, health and wellness needs consolidated in a single platform</p>
          
          <div class="flex flex-wrap mb-5 pb-10 -m-8">
           
           
            <div class="w-full md:w-1/2 p-8 mt-10 lg:mt-14">
              <div class="md:max-w-xs">
                <div class="flex flex-wrap -m-2">
                  <div class="w-auto p-2">
                    <svg class="mt-1" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="flex-1 p-2">
                    <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">User-Defined</h3>
                    <p class="text-gray-600 font-medium leading-relaxed">Features specific to users and data defined dashboard</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full md:w-1/2 p-8 mt-6 lg:mt-14">
              <div class="md:max-w-xs">
                <div class="flex flex-wrap -m-2">
                  <div class="w-auto p-2">
                    <svg class="mt-1" width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 3V5M15 3V5M9 19V21M15 19V21M5 9H3M5 15H3M21 9H19M21 15H19M7 19H17C18.1046 19 19 18.1046 19 17V7C19 5.89543 18.1046 5 17 5H7C5.89543 5 5 5.89543 5 7V17C5 18.1046 5.89543 19 7 19ZM9 9H15V15H9V9Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="flex-1 p-2">
                    <h3 class="mb-2 text-xl font-semibold leading-normal text-indigo-900">Feature-rich</h3>
                    <p class="text-gray-600 font-medium leading-relaxed">Unlimit bottom heading text - Something for Everyone</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class="md:inline-block">
            <button class="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="button">Get Started Now</button>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}
